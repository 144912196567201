import React from "react"
import cx from "classnames"

import Feedback from "~/models/feedback"
import Image from "~/ui/elements/image/image"
import Stars from "~/ui/elements/review/stars"

type Props = {
  className?: string
  feedback: Feedback
}

function FeedbackCard({ className, feedback }: Props) {
  return (
    <div className={cx(className)}>
      <div className="w-[296px] lg:w-[357px] p-3 lg:p-4 bg-white rounded-lg shadow-card-light min-h-[200px] lg:min-h-[240px]">
        <div className="flex gap-3 lg:gap-x-4 mb-4">
          <div className="w-[48px] h-[48px] lg:w-[56px] lg:h-[56px] rounded-full flex-shrink-0 overflow-hidden z-10">
            <Image
              className="block"
              gatsbyImageData={feedback.avatar}
              alt={feedback.contributor}
            />
          </div>
          <div>
            <div className="mb-1 lg:mb-0 text-lg lg:text-2xl text-font-1 font-medium">
              {feedback.contributor}
            </div>
            <div className="flex gap-x-2 items-center">
              <Stars rating={feedback.rating} />
              <span className="text-sm lg:text-lg leading-none text-font-2 font-medium">
                {feedback.rating}
              </span>
            </div>
          </div>
        </div>
        <p className="text-sm lg:text-lg text-font-2">{feedback.content}</p>
      </div>
    </div>
  )
}

export default FeedbackCard
