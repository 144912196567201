import React from "react"

type Props = {
  className?: string
}

function Games({ className }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.6963 4C9.79288 4.07094 7.97769 4.82076 6.5791 6.11383L8.03089 7.56565C9.04298 6.65591 10.3372 6.12194 11.6963 6.05333V4Z"
        fill="#5E6EFF"
      />
      <path
        d="M7.58212 15.9862C6.66233 14.9715 6.12208 13.6697 6.05311 12.3018H4C4.07129 14.214 4.82761 16.0367 6.13098 17.4376L7.58212 15.9862Z"
        fill="#5E6EFF"
      />
      <path
        d="M17.8694 6.5625L16.418 8.01387C17.3378 9.02862 17.8782 10.3305 17.9471 11.6984H20.0002C19.929 9.78624 19.1728 7.96343 17.8694 6.5625Z"
        fill="#5E6EFF"
      />
      <path
        d="M11.6982 19.9986V17.9455C10.3304 17.8765 9.02858 17.3362 8.01387 16.4163L6.5625 17.8677C7.96337 19.171 9.78613 19.9273 11.6982 19.9986Z"
        fill="#5E6EFF"
      />
      <path
        d="M17.4376 6.13109C16.0368 4.82763 14.2139 4.07126 12.3018 4V6.05311C13.6697 6.12216 14.9715 6.66255 15.9863 7.5825L17.4376 6.13109Z"
        fill="#5E6EFF"
      />
      <path
        d="M7.59931 7.99299L6.14825 6.54192C4.83447 7.94507 4.0716 9.77546 4 11.6963H6.05311C6.12257 10.3197 6.66929 9.01029 7.59931 7.99299Z"
        fill="#5E6EFF"
      />
      <path
        d="M11.9993 17.3451C14.9476 17.3451 17.3462 14.9466 17.3462 11.9981C17.3462 9.04953 14.9476 6.65096 11.9993 6.65096C9.05087 6.65096 6.65234 9.04976 6.65234 11.9981C6.65234 14.9465 9.05099 17.3451 11.9993 17.3451ZM9.34582 11.8296L11.7467 8.23588C11.7745 8.19439 11.812 8.16039 11.856 8.13687C11.9001 8.11335 11.9492 8.10105 11.9991 8.10105C12.049 8.10105 12.0982 8.11335 12.1422 8.13687C12.1862 8.16039 12.2237 8.19439 12.2515 8.23588L14.6524 11.8298C14.6857 11.8797 14.7035 11.9384 14.7035 11.9984C14.7035 12.0584 14.6857 12.1171 14.6524 12.1671L12.2515 15.7609C12.2237 15.8024 12.1862 15.8364 12.1422 15.86C12.0982 15.8835 12.049 15.8958 11.9991 15.8958C11.9492 15.8958 11.9001 15.8835 11.856 15.86C11.812 15.8364 11.7745 15.8024 11.7467 15.7609L9.34582 12.1673C9.31239 12.1173 9.29454 12.0585 9.29454 11.9984C9.29454 11.9383 9.31239 11.8795 9.34582 11.8296Z"
        fill="#5E6EFF"
      />
      <path
        d="M17.9498 12.3018C17.8812 13.6609 17.3472 14.9551 16.4375 15.9672L17.8893 17.419C19.1824 16.0204 19.9322 14.2052 20.0031 12.3018H17.9498Z"
        fill="#5E6EFF"
      />
      <path
        d="M12.3018 20C14.2226 19.9284 16.053 19.1655 17.4562 17.8518L16.0051 16.4007C14.9878 17.3307 13.6783 17.8773 12.3018 17.9467V20Z"
        fill="#5E6EFF"
      />
      <path
        d="M14.0355 12.0001L11.9997 8.95288L9.96387 12.0001L11.9997 15.0472L14.0355 12.0001Z"
        fill="#5E6EFF"
      />
    </svg>
  )
}

export default Games
