import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import formatdate from "~/helpers/formatdate"
import Article from "~/models/article"
import Image from "~/ui/elements/image/image"

type Props = {
  article: Article
  className: string
}

function ArticleCard({ article, className }: Props) {
  return (
    <Link
      to={article.page.url.pathname}
      className={cx(
        "flex flex-col shadow-card p-5 bg-white rounded-xl",
        className
      )}
    >
      <div className="rounded-md overflow-hidden mb-6 z-10 relative">
        <Image
          className="block"
          gatsbyImageData={article.cover}
          alt={article.title}
          zoom
        />
      </div>

      <div className="flex flex-col gap-3 justify-between flex-1">
        <div>
          <div className="mb-2 text-xl text-font-1 font-medium">
            {article.title}
          </div>

          <div className="max-h-[105px] flex-1 relative overflow-hidden text-sm text-font-2">
            {article.page.blocks[0]?.content.childMdx?.excerpt}
            <div className="absolute right-0 bottom-0 bg-white text-sm ">
              <span className="text-font-2">...</span>
              <span className="text-font-1">read more</span>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="mr-2 w-9 h-9 flex-shrink-0 rounded-full overflow-hidden z-10">
            <Image
              className="block h-full"
              gatsbyImageData={article.author.avatar}
              alt={article.author.name}
            />
          </div>
          <div className="flex w-full justify-between content-between items-center">
            <span className="text-sm">
              <span className="mr-1 text-font-2">By</span>
              <span className="text-font-1">{article.author.name}</span>
            </span>
            <span className="text-sm text-font-2">
              {formatdate(article.createdAt)}
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard
