import { ContentfulAsset } from "../../types/graphql-types"

export default class Feedback {
  id: string
  avatar: ContentfulAsset
  contributor: string
  rating: number
  content: string

  constructor(feedback: any) {
    this.id = feedback.id
    this.avatar =
      feedback.avatar?.localFile?.childImageSharp?.gatsbyImageData ||
      feedback.avatar?.gatsbyImageData
    this.contributor = feedback.contributor
    this.rating = feedback.rating
    this.content = feedback.content?.content! || ""
  }
}
