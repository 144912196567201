import React from "react"

type Props = {
  className?: string
}

function CasinoReview({ className }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.45893 13.1109C9.49635 13.1109 9.53377 13.1109 9.57118 13.1109L13.687 13.2606C14.1547 13.2793 14.5663 13.4664 14.8843 13.8218C14.903 13.8405 14.9404 13.8779 14.9779 13.9154L17.5035 13.1296C17.5409 13.1109 17.597 13.1109 17.6344 13.1109C18.495 12.1381 19.0001 10.8659 19.0001 9.48151C19.0001 6.45078 16.5494 4 13.5186 4C10.4879 4 8.03711 6.45078 8.03711 9.48151C8.03711 10.8846 8.56094 12.1568 9.42152 13.1296H9.45893V13.1109ZM13.0135 6.45078V5.94566C13.0135 5.83341 13.107 5.73987 13.2193 5.73987H13.7992C13.9115 5.73987 14.005 5.83341 14.005 5.94566V6.45078C14.6037 6.52561 15.0714 6.99332 15.1462 7.61069C15.1462 7.66681 15.1275 7.72294 15.0901 7.76036C15.034 7.77906 14.9779 7.81648 14.9217 7.81648H14.3418C14.2482 7.81648 14.1734 7.76036 14.1547 7.66681C14.1173 7.51715 13.9863 7.42361 13.8554 7.42361H13.3315C13.0696 7.42361 12.8264 7.61069 12.8077 7.8726C12.789 8.02227 12.8451 8.15323 12.9387 8.24677C13.0322 8.35902 13.1632 8.41514 13.3128 8.41514H13.7618C14.1734 8.41514 14.585 8.58352 14.8656 8.90156C15.1462 9.2196 15.2772 9.61247 15.2398 10.0428C15.1837 10.6788 14.6972 11.2027 14.0425 11.3336V11.8575C14.0425 11.9697 13.9489 12.0632 13.8367 12.0632H13.2567C13.1445 12.0632 13.0509 11.9697 13.0509 11.8575V11.3523C12.4523 11.2775 11.9845 10.8098 11.9097 10.1924C11.9097 10.1363 11.9284 10.0802 11.9658 10.0428C12.0033 10.0053 12.0594 9.96793 12.1155 9.96793H12.6768C12.7703 9.96793 12.8451 10.0241 12.8825 10.1176C12.92 10.2673 13.0509 10.3608 13.1819 10.3608H13.7057C13.9676 10.3608 14.2108 10.1737 14.2295 9.9118C14.2482 9.76214 14.1921 9.63118 14.0986 9.53764C14.005 9.42539 13.8741 9.36926 13.7244 9.36926H13.3502C12.5645 9.36926 11.891 8.78931 11.8162 8.04098C11.7413 7.27394 12.2465 6.58174 13.0135 6.45078Z"
        fill="#5E6EFF"
      />
      <path
        d="M4.14643 17.6592L3.08006 19.0623C2.94911 19.212 2.98652 19.4365 3.13619 19.5674L5.94242 21.6815C6.1108 21.7937 6.3353 21.775 6.44754 21.6066L7.51391 20.2035L4.22127 17.7153C4.20256 17.6966 4.16514 17.6779 4.14643 17.6592Z"
        fill="#5E6EFF"
      />
      <path
        d="M17.7664 14.065L15.2034 14.8694C15.2034 14.9255 15.2034 15.0004 15.2034 15.0565C15.166 15.7861 14.6421 16.3848 13.9312 16.5344L13.7628 16.5719C12.8274 16.7589 11.8733 16.8899 10.9192 16.9086L10.1335 16.9273C9.89024 16.9273 9.68445 16.7402 9.68445 16.5157C9.68445 16.2725 9.87154 16.0667 10.096 16.0667L10.8818 16.048C11.7798 16.0106 12.6965 15.8984 13.5758 15.73L13.7441 15.6926C14.0622 15.6177 14.3054 15.3558 14.3241 15.0191C14.3428 14.832 14.268 14.6449 14.137 14.4953C14.0061 14.3456 13.819 14.2708 13.6319 14.252L9.53479 14.1024C9.08579 14.0837 8.61808 14.2146 8.24392 14.4953L4.82031 16.946L8.35617 19.6213L11.7985 19.9581C12.3971 20.0142 12.9958 19.8458 13.4822 19.4716L18.5522 15.5429C18.7392 15.3932 18.8515 15.1875 18.8702 14.9442C18.8889 14.701 18.8141 14.4765 18.6457 14.2895C18.4212 14.0463 18.0844 13.9714 17.7664 14.065Z"
        fill="#5E6EFF"
      />
    </svg>
  )
}

export default CasinoReview
