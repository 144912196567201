import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"

import Page from "~/models/page"
import CasinoCollection from "~/models/casino-collection"
import Casino from "~/models/casino"
import Feedback from "~/models/feedback"
import Article from "~/models/article"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import HeroBlock from "~/ui/compositions/generic/hero"
import VerificationsBlock from "~/ui/compositions/generic/verifications"
import CasinoToplistBlock from "~/ui/compositions/casino/toplist"
import CardGridBlock from "~/ui/compositions/generic/card-grid"
import BlacklistBlock from "~/ui/compositions/casino/blacklist"
import FeedbacksBlock from "~/ui/compositions/feedback/list"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import ArticlesBlock from "~/ui/compositions/article/list"
import ParagraphsBlock from "~/ui/compositions/generic/paragraphs"
import AboutUsBlock from "~/ui/compositions/generic/about-us"
import SerpTable from "~/ui/components/stable/serp-table"

import {
  IndexQuery,
  ContentfulPage,
  ContentfulCasinoCollection,
  ContentfulCasino,
  ContentfulArticle,
} from "../../../types/graphql-types"

type Props = {
  data: IndexQuery
}

function IndexPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const casinoCollections = new CasinoCollection(
    data.contentfulCasinoCollection as ContentfulCasinoCollection
  )
  const blacklistCasinos = data.allContentfulCasino.nodes.map(casino => {
    return new Casino(casino as ContentfulCasino)
  })
  const feedbacks = data.allContentfulFeedback.nodes.map(feedback => {
    return new Feedback(feedback as ContentfulCasino)
  })
  const articles = data.allContentfulArticle.nodes.map(article => {
    return new Article(article as ContentfulArticle)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />

        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "Hero" && <HeroBlock {...block.props} />}
            {block.name == "Verifications" && (
              <VerificationsBlock {...block.props} />
            )}
            {block.name == "CasinoToplist" && (
              <CasinoToplistBlock
                rank
                collection={casinoCollections}
                className="pt-6 lg:pt-[48px]"
                {...block.props}
              />
            )}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "AboutUs" && <AboutUsBlock {...block.props} />}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "Blacklist" && (
              <BlacklistBlock casinos={blacklistCasinos} {...block.props} />
            )}
            {block.name == "Feedbacks" && (
              <FeedbacksBlock feedbacks={feedbacks} {...block.props} />
            )}
            {block.name == "Articles" && (
              <ArticlesBlock articles={articles} {...block.props} />
            )}
            {block.name == "CollapsibleList" && page.serpTable && (
              <SerpTable serpTable={page.serpTable} />
            )}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    contentfulPage(url: { pathname: { eq: "/" } }) {
      ...PageFields
    }
    contentfulCasinoCollection(type: { eq: "toplist" }) {
      ...CasinoCollectionFields
    }
    allContentfulCasino(filter: { rating: { eq: 1 } }, limit: 3) {
      nodes {
        ...CasinoFields
      }
    }
    allContentfulFeedback(limit: 10) {
      nodes {
        ...FeedbackFields
      }
    }
    allContentfulArticle(sort: { fields: createdAt, order: DESC }, limit: 6) {
      nodes {
        createdAt,
        ...ArticleFields,
      }
    }
  }
`
