import React, { useState, useEffect } from "react"
import { globalHistory } from "@reach/router"
import cx from "classnames"

import Close from "~/ui/identity/icon/close"
import Button from "~/ui/elements/button/button"
import Check from "~/ui/identity/icon/check"

type Props = {
  isActive: boolean
  close: () => void
}

enum State {
  Default,
  Submitting,
  Submitted,
}

function ModalContainer(props: Props) {
  return (
    <React.Fragment>{props.isActive && <Modal {...props} />}</React.Fragment>
  )
}

function Modal({ isActive, close }: Props) {
  const [state, setState] = useState(State.Default)
  const [name, setName] = useState("")
  const [rating, setRating] = useState(3)
  const [description, setDescription] = useState("")

  const [errors, setErrors] = useState({
    name: false,
    description: false,
  })

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (["PUSH", "POP"].indexOf(action) != -1) {
        close()
      }
    })

    const scrollY = window.scrollY || 0

    if (scrollY > 0) {
      document.body.style.top = `-${scrollY}px`
    }

    document.body.classList.add("fixed-body")

    return function cleanup() {
      const scrollY = document.body.style.top

      document.body.classList.remove("fixed-body")
      document.body.style.top = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }
  }, [])

  function handleClose(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    close()
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const endpoint = process.env.GATSBY_GETFORM as string

    const data = {
      type: "feedback",
      name,
      description,
    }

    setErrors({
      name: name.length == 0,
      description: description.length == 0,
    })

    if (name.length == 0 || description.length == 0) {
      return
    }

    try {
      setState(State.Submitting)

      await new Promise(s => setTimeout(s, 3000))

      let response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      setState(State.Submitted)
    } catch (error) {
      setState(State.Default)
    }
  }

  return (
    <React.Fragment>
      {isActive && (
        <div className="fixed flex items-center inset-0 bg-black-dark-90 z-50 p-4 lg:p-5 overflow-y-auto">
          <div className="flex flex-col lg:flex-row w-full max-w-screen-lg mx-auto bg-gray-100 rounded-[20px] overflow-hidden">
            <div className="lg:flex-1 p-4 lg:py-8 lg:px-10">
              <div className="lg:hidden flex justify-end">
                <button className="p-2" onClick={e => handleClose(e)}>
                  <Close width={24} fill="#ffffff" />
                </button>
              </div>

              <p className="text-3xl lg:text-6xl font-bold text-white">
                Feel free to drop us your feedback!
              </p>
            </div>
            <div className="flex-1 bg-white rounded-[20px] p-4 lg:py-8 lg:px-10">
              <div className="hidden lg:flex justify-end">
                <button className="p-2" onClick={e => handleClose(e)}>
                  <Close width={24} />
                </button>
              </div>

              <form className="" onSubmit={e => onSubmit(e)}>
                <div className="mb-8">
                  <label
                    className="block mb-3 text-lg font-medium text-font-1"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className={cx(
                      "appearance-none block w-full py-3 px-4 rounded-lg focus:outline-font-1 text-font-1 border border-orange-500",
                      {
                        "border-red-400 border-2": errors.name,
                      }
                    )}
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Type for Full Name"
                    value={name}
                    onChange={e => {
                      setErrors({
                        ...errors,
                        name: false,
                      })
                      setName(e.target.value)
                    }}
                  />
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-3 text-lg font-medium text-font-1"
                    htmlFor="rating"
                  >
                    How much would you rate us?
                  </label>
                  <div className="flex justify-between">
                    {Array.from({ length: 5 }).map((_, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          className={cx(
                            "w-[30px] h-[30px] flex items-center justify-center  rounded-full",
                            {
                              "bg-orange-500 text-white": index + 1 == rating,
                              "bg-gray-200 text-font-1": index + 1 != rating,
                            }
                          )}
                          onClick={_ => setRating(index + 1)}
                        >
                          <span className="font-mediu">{index + 1}</span>
                        </button>
                      )
                    })}
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex flex-col items-start gap-1">
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M7 9.44434L13.0622 0.111002H0.937822L7 9.44434Z"
                          fill="#C4C4C4"
                        />
                      </svg>
                      <span className="text-xs text-font-2">
                        Not at all Satisfied
                      </span>
                    </div>

                    <div className="flex flex-col items-end gap-1">
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M7 9.44434L13.0622 0.111002H0.937822L7 9.44434Z"
                          fill="#C4C4C4"
                        />
                      </svg>
                      <span className="text-xs text-font-2">
                        Extremely Satisfied
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-3 text-lg font-medium text-font-1"
                    htmlFor="message"
                  >
                    Description
                  </label>
                  <textarea
                    className={cx(
                      "h-36 appearance-none block w-full py-3 px-4 rounded-lg focus:outline-font-1 text-font-1 border border-orange-500",
                      {
                        "border-red-400 border-2": errors.description,
                      }
                    )}
                    id="message"
                    name="message"
                    placeholder="Enter Description"
                    value={description}
                    onChange={e => {
                      setErrors({
                        ...errors,
                        description: false,
                      })
                      setDescription(e.target.value)
                    }}
                  ></textarea>
                </div>

                <Button
                  type="secondary"
                  className={cx("inline-block", {
                    "pointer-events-none": state != State.Default,
                  })}
                >
                  <div className="flex gap-3 items-center">
                    {state == State.Submitting && (
                      <div className="loader"></div>
                    )}
                    {state == State.Submitted && <Check width={24} />}
                    <div>Submit</div>
                  </div>
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ModalContainer
