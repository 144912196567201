import React from "react"
import { StaticImage } from "gatsby-plugin-image"

type Props = {}

function Verifications({}: Props) {
  return (
    <section className="max-w-screen-xl mx-auto pt-12 lg:pt-20 px-4 md:px-10 xl:px-20">
      <div className="mb-4 text-font-2 text-base lg:text-lg">
        Checked and Verified by:
      </div>
      <div className="flex items-center">
        <img
          src="/images/verification/verisign.svg"
          alt="Verified Secured"
          className="mr-4 lg:mr-8 w-[56px] lg:w-[82px]"
          decoding="async"
          width={82}
          height={40}
        />
        <img
          src="/images/verification/itech.svg"
          alt="iTech Labs logo"
          className="mr-4 lg:mr-8 w-[32px] lg:w-[40px]"
          decoding="async"
          width={40}
          height={40}
        />
        <img
          src="/images/verification/ecogra.svg"
          alt="eCOGRA logo"
          className="mr-4 lg:mr-8 w-[56px] lg:w-[102px]"
          decoding="async"
          width={102}
          height={40}
        />
        <img
          src="/images/verification/18+.svg"
          alt="18+ logo"
          className="mr-4 lg:mr-8 w-[32px] lg:w-[40px]"
          decoding="async"
          width={40}
          height={40}
        />
        <StaticImage
          src="../../../images/verification/ssl.png"
          alt="SSL Encryption logo"
          placeholder="none"
          className="w-[56px] lg:w-[102px]"
          objectFit="contain"
          formats={["webp"]}
          width={102}
        />
      </div>
    </section>
  )
}

export default Verifications
