import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import RealMoney from "~/ui/identity/icon/real-money"
import CasinoReview from "~/ui/identity/icon/casino-review"
import Bonus from "~/ui/identity/icon/bonus"
import Games from "~/ui/identity/icon/games"

type HeroProps = {
  title: string
  description: string
  className?: string
}

function Hero({ className, title, description }: HeroProps) {
  return (
    <section className={cx("bg-orange-500 overflow-hidden", className)}>
      <div className="relative flex items-center max-w-screen-xl mx-auto py-5 px-4 md:px-10 xl:px-20">
        <img
          src="/images/general/mandala-orange-2.svg"
          alt=""
          className="w-[220px] absolute -bottom-[36px] -right-[92px] lg:-top-[15px] lg:-right-[31px]"
          decoding="async"
        />

        <div className="z-10">
          <h1 className="lg:max-w-[500px] mb-1 lg:mb-5 text-font-1 font-bold text-4xl lg:text-6xl tracking-[-0.025em]">
            {title}
          </h1>

          <p className="lg:max-w-[500px] text-font-1 text-base lg:text-lg mb-9">
            {description}
          </p>

          <div>
            <Nav
              label="Real Money Casinos"
              to="/casinos"
              className="mr-2 lg:mr-3"
            >
              <RealMoney className="mr-1" />
            </Nav>
            <Nav label="Casino Reviews" to="/casinos" className="mr-2 lg:mr-3">
              <CasinoReview className="mr-1" />
            </Nav>
            <Nav label="Casino Bonus" to="/bonuses" className="mr-2 lg:mr-3">
              <Bonus className="mr-1" />
            </Nav>
            <Nav label="Free Games" to="/free-games">
              <Games className="mr-1" />
            </Nav>
          </div>
        </div>

        <img
          src="/images/general/hero.svg"
          alt="Online Gambling Sites"
          className="hidden sm:block flex-shrink-0 z-10 w-[280px] lg:w-[350px]"
          decoding="async"
        />

        <img
          src="/images/general/mandala-orange-1.svg"
          alt=""
          className="w-[200px] lg:w-[704px] absolute -top-[32px] lg:-top-[68px] -left-[60px] lg:-left-[206px]"
          decoding="async"
        />
      </div>
    </section>
  )
}

type NavProps = {
  children: JSX.Element
  label: string
  to: string
  className?: string
}

function Nav({ children, label, className, to }: NavProps) {
  return (
    <Link
      to={to}
      className={cx(
        "btn-nav inline-block mb-2 xl:mb-0 py-1 px-2 lg:p-3 bg-white text-font-1 hover:text-white text-sm lg:text-base font-bold hover:bg-blue-600 rounded",
        className
      )}
    >
      <span className="flex items-center">
        {children}
        <span className="leading-6">{label}</span>
      </span>
    </Link>
  )
}

export default Hero
