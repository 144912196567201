import { ContentfulArticle, ContentfulAsset } from "../../types/graphql-types"

import Author from "./author"
import Page from "./page"

export default class Article {
  id: string
  slug: string
  cover: ContentfulAsset
  title: string
  author: Author
  page: Page
  createdAt: Date

  constructor(article: ContentfulArticle) {
    this.id = article.id
    this.slug = article.slug || ""
    this.cover =
      article.cover?.localFile?.childImageSharp?.gatsbyImageData ||
      article.cover?.gatsbyImageData
    this.title = article.title || ""
    this.author = new Author(article.author!)
    this.page = new Page(article.page!)
    this.createdAt = new Date(article.createdAt)
  }
}
