import React, { useState } from "react"
import cx from "classnames"

import Feedback from "~/models/feedback"
import Button from "~/ui/elements/button/button"
import FeedbackCard from "~/ui/components/feedback/card"
import Carousel from "~/ui/layout/carousel"
import FeedbackModal from "~/ui/compositions/feedback/modal"

type Props = {
  title: string
  feedbacks: Feedback[]
  className?: string
}

function FeedbackList({ title, feedbacks, className }: Props) {
  const [modal, setModal] = useState(false)

  return (
    <section className={cx("overflow-hidden bg-[#FFFABF]", className)}>
      <FeedbackModal isActive={modal} close={() => setModal(false)} />

      <div className="relative max-w-screen-xl mx-auto py-10 md:py-16 md:pl-10 md:pr-0 xl:px-20">
        <img
          src="/images/general/mandala-yellow-2.svg"
          alt=""
          className="w-[220px] absolute -bottom-[36px] -right-[92px] lg:-top-[15px] lg:-right-[31px]"
          decoding="async"
        />

        <div className="px-4 relative mb-6 lg:mb-14  z-10 lg:px-52">
          <h2 className="flex-1 text-2xl font-bold text-font-1 lg:text-5xl text-center">
            {title}
          </h2>
        </div>

        <Carousel itemsPerPage={3} dummyCardClassName="w-[296px] lg:w-[357px]">
          {feedbacks.map(feedback => {
            return (
              <FeedbackCard
                key={feedback.id}
                feedback={feedback}
                className="snap-start flex-shrink-0 pl-4 pr-2 last:pr-4 lg:px-0"
              />
            )
          })}
        </Carousel>

        <div className="relative z-10 text-center">
          <Button type="primary" onClick={_ => setModal(true)}>
            Share Feedback
          </Button>
        </div>

        <img
          src="/images/general/mandala-yellow-1.svg"
          alt=""
          className="w-[500px] lg:w-[704px] absolute bottom-[232px] lg:-bottom-[300px] -left-[60px] lg:-left-[206px]"
          decoding="async"
        />
      </div>
    </section>
  )
}

export default FeedbackList
