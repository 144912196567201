import React from "react"
import cx from "classnames"
import STable from "~/models/serp-table"
import sanitize from "~/helpers/sanitize"

type Props = {
  serpTable: STable
}

function SerpTable({ serpTable }: Props) {
  return (
    <section className={cx("overflow-hidden bg-[#E0F7fB]")}>
      <div className="relative max-w-screen-xl mx-auto py-10 md:py-16 md:pl-10 md:pr-0 xl:px-20">
        <div className="serp-table-wrapper">
          <table>
            <thead>
              {serpTable.name === "Overview" && (
                <tr>
                  <th>Category</th>
                  <th>Online Casino</th>
                </tr>
              )}
              {serpTable.name !== "Overview" && (
                <tr>
                  <th colSpan={2}>{serpTable.name}</th>
                </tr>
              )}
            </thead>
            <tbody>
            {serpTable.table.rows.map((row: { cells: { value: any }[] }) => (
              <tr>
                <td>
                  {row.cells[0].value}
                </td>
                <td dangerouslySetInnerHTML={sanitize(row.cells[1].value)}>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default SerpTable;