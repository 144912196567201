import React from "react"

import Article from "~/models/article"
import Button from "~/ui/elements/button/link"
import ArticleCard from "~/ui/components/article/card"
import TitledSection from "~/ui/layout/titled-section"

type Props = {
  title: string
  description?: string
  articles: Article[]
  button: ButtonProps
}

type ButtonProps = {
  label: string
  link: string
}

function ArticleList({ title, description, articles, button }: Props) {
  return (
    <TitledSection title={title} description={description}>
      <React.Fragment>
        <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {articles.map((article, index) => {
            return <ArticleCard key={index} article={article} className="" />
          })}
        </div>

        {button && (
          <div className="relative z-10 text-center">
            <Button type="primary" to={button.link}>
              {button.label}
            </Button>
          </div>
        )}
      </React.Fragment>
    </TitledSection>
  )
}

export default ArticleList
