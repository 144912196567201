import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import LocalImage from "~/ui/elements/image/local"

type Props = {
  title: string
  description: string
  items: CardProps[]
  background: "yellow" | "white"
}

AboutUs.defaultProps = {
  background: "white",
}

function AboutUs({ title, description, items, background }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background={background}
    >
      <div className="grid gap-6 md:grid-cols-3">
        {items.map((item, index) => {
          return (
            <Card
              key={index}
              image={item.image}
              body={item.body}
              className="shadow-card"
            />
          )
        })}
      </div>
    </TitledSection>
  )
}

type CardProps = {
  image: string
  body: string
  className?: string
}

function Card({ image, body, className }: CardProps) {
  return (
    <div className={cx("p-5 bg-white rounded-xl", className)}>
      <img
        src={`/images/${image}`}
        alt=""
        className="w-full mb-6 block rounded-lg isolate"
        decoding="async"
      />

      <p className="text-font-1">{body}</p>
    </div>
  )
}

export default AboutUs
